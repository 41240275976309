import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@mui/styles';
import { Divider, IconButton, Typography } from '@mui/material';
import {
    Call,
    Queue,
    Person,
    Input,
    Info,
    Contacts,
    Link,
    Refresh,
    WrapText

} from '@mui/icons-material';
import DropDown from '../../components/Inputs/DropDown';
import { IntegrationService } from '../../services/IntegrationService';
import { getLocalStorage, HandleServerResponse, LogInformation, setLocalStorage } from '../../helpers/Common';
import { useDispatch, useSelector } from 'react-redux';
import { CallService } from '../../services/CallService';
import { CommunicationType } from '../../helpers/Constant';
import { AddIntegrationUser } from '../app/InteractionSlice';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    infoFont: {
        fontWeight: 500
    },
    dropdownStype: {
        height: 25,
        marginLeft: 4
    },
    nameRoot: {
        width: '100%',
        display: 'flex'
    },
    wrapName: {
        display: 'flex',
        alignItems: 'center',
        width: 64
    },
    wrapDropdown: {
        display: 'flex',
        marginLeft: 8,
        alignItems: 'center',
        width: '60%'
    }
}));

const openIntegrationUrl = (source) => {
    try {
        window.open(source);
    } catch (error) {
        LogInformation(error);
    }
}

const ContactInfo = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        alignAvatar: {
            fontSize: '1rem',
            marginRight: theme.spacing(1),
        },
        typographyStyle: {
            display: 'flex',
            alignItems: 'center'
        },
        iconBtn: {
            ml: theme.spacing(1.25)
        }
    }

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { integrations } = useSelector((state) => state.interaction);
    const [integrationLoading, setIntegrationLoading] = React.useState(false);
    const [itegrationItemLoading, setItegrationItemLoading] = React.useState(false);
    const [intergrationDataLoaded, setIntergrationDataLoaded] = React.useState(false);
    const [integrationDropdownData, setIntegrationDropdownData] = React.useState([]);
    const [integrationContactData, setIntegrationContactData] = React.useState(null);
    const [selectedIntegrationCalle, setSelectedIntegrationCalle] = React.useState(null);
    const [showSyncIntegrationBtn, setShowSyncIntegrationBtn] = React.useState(props.callStatus === CommunicationType.invitecall ? false : true);

    const handleIntegrationDataChange = (e) => {
        e.preventDefault();
        if (e.target.value !== "") {
            setItegrationItemLoading(true);

            let res = integrationContactData.list.find(x => x.id === e.target.value);

            setSelectedIntegrationCalle(res ?? null);

            let agentData = {
                chainId: props.chainId,
                integrationId: e.target.value ?? null
            }
            dispatch(AddIntegrationUser(agentData));

            let initialNoteIntegration = {
                id: e.target.value ?? null,
                integrationType: integrationContactData?.integrationType ?? null,
                entityType: integrationContactData?.entityType ?? null,
                connectorId: integrationContactData?.connectorId ?? null
            }

            props.setSelectedIntegrationData(initialNoteIntegration);

            setItegrationItemLoading(false);
        }
    }

    const syncIntegrationData = () => {
        (async () => {
            let params = {
                ChainId: props.chainId,
                ReferenceId: props.referenceId,
                Id: selectedIntegrationCalle?.id,
                Type: integrationContactData.integrationType,
                EntityType: integrationContactData.entityType,
                QueueId: props.queueId,
                ConnectorId: integrationContactData.connectorId
            }

            let result = await CallService.updateCallIntegrationData(params);
            HandleServerResponse(result, "Integration data synchronized", dispatch);
        })();
    }

    const refreshIntegrationDataHandle = () => {
        //setIntegrationenabled to false
        setIntergrationDataLoaded(false);
    }

    React.useEffect(() => {
        setShowSyncIntegrationBtn(props.callStatus === CommunicationType.invitecall ? false : true)
    }, [props.callStatus])

    React.useEffect(() => {
        let active = true;

        if (Boolean(props.source) && Boolean(props.queueId) && !intergrationDataLoaded) {
            (async () => {
                setIntegrationLoading(true);

                let userInputParam = props.userInput ?? null
                let integrationDataResult = await IntegrationService.GetIntegrationData(props.source, props.chainId, props.referenceId, props.queueId, userInputParam); //number | chainId | referenceId | dtmf

                HandleServerResponse(integrationDataResult, "", dispatch);
                if (!integrationDataResult.success) {
                    setIntegrationLoading(false);
                    return;
                }

                setIntegrationContactData(integrationDataResult?.data ?? null);
                setShowSyncIntegrationBtn(integrationDataResult?.data.journalingOn);
                if (integrationDataResult?.data?.list?.length > 0) {
                    let dropdownDataList = [];

                    integrationDataResult.data.list.forEach(calle => {
                        if (calle?.id ?? false) {
                            let calleObj = { id: calle?.id, name: calle?.selectionField ?? "Unknown", fieldName: calle?.selectionFieldName };
                            dropdownDataList.push(calleObj);
                        }
                    });
                    setIntegrationDropdownData(dropdownDataList);

                    let reduxSavedIntegrationUser = integrations[props.chainId];

                    let hasInitialDataSet = false;

                    if (reduxSavedIntegrationUser ?? false) {
                        let previouslyChangedData = integrationDataResult.data.list.find(x => x.id === reduxSavedIntegrationUser);
                        if (previouslyChangedData ?? false) {
                            setSelectedIntegrationCalle(previouslyChangedData);

                            let initialNoteIntegration = {
                                id: previouslyChangedData.id ?? null,
                                integrationType: integrationDataResult?.data?.integrationType ?? null,
                                entityType: integrationDataResult?.data?.entityType ?? null,
                                connectorId: integrationDataResult?.data?.connectorId ?? null
                            }
                            props.setSelectedIntegrationData(initialNoteIntegration);

                            hasInitialDataSet = true;
                        }
                    }

                    if (!hasInitialDataSet) {
                        let initialNoteIntegration = {
                            id: null,
                            integrationType: integrationDataResult?.data?.integrationType ?? null,
                            entityType: integrationDataResult?.data?.entityType ?? null,
                            connectorId: integrationDataResult?.data?.connectorId ?? null
                        }
                        let setCalleData = false;
                        if (integrationDataResult?.data?.list?.[0].id ?? false) {
                            setSelectedIntegrationCalle(integrationDataResult?.data?.list?.[0] ?? null);

                            initialNoteIntegration.id = integrationDataResult?.data?.list?.[0]?.id;
                            setCalleData = true;
                        }

                        if (!setCalleData && (integrationDataResult?.data?.list?.[1].id ?? false)) {
                            setSelectedIntegrationCalle(integrationDataResult?.data?.list?.[1] ?? null);
                            initialNoteIntegration.id = integrationDataResult?.data?.list?.[1]?.id;
                        }
                        props.setSelectedIntegrationData(initialNoteIntegration);
                    }
                }
                setIntergrationDataLoaded(true);
                setIntegrationLoading(false);

                //Open External Link
                const chainId = getLocalStorage(`CP_External_Link`);

                if (chainId !== props.chainId && props.callStatus === CommunicationType.activecall && integrationDataResult.data?.externalUrl !== "" && !props.attendantconsole) {
                    let updatedUrl = integrationDataResult.data?.externalUrl.replace("{$SYSTEM.PHONE}", props.source)
                    window.open(updatedUrl, '_blank');

                    setLocalStorage(`CP_External_Link`, props.chainId)
                }
            })();
        }

        if (!active) { return; }

        return () => { active = false; };
    }, [intergrationDataLoaded]);

    return (
        <React.Fragment>
            <Typography variant="body2" sx={styles.typographyStyle}>
                <Call color="primary" sx={styles.alignAvatar} />
                {t("Phone")}:&nbsp;&nbsp;<span className={classes.infoFont}>{props.source}</span>
            </Typography>
            {
                props.queueName != null &&
                <Typography variant="body2" sx={styles.typographyStyle}>
                    <Queue color="primary" sx={styles.alignAvatar} />
                    {t("Queue")}:&nbsp;&nbsp;<span className={classes.infoFont}>{props.queueName}</span>
                </Typography>
            }
            {
                props.callFlow != null &&
                <Typography variant="body2" sx={styles.typographyStyle}>
                    <WrapText color="primary" sx={styles.alignAvatar} />
                    {t("Call Flow")}:&nbsp;&nbsp;<span className={classes.infoFont}>{props.callFlow}</span>
                </Typography>
            }

            {(!integrationLoading && intergrationDataLoaded) &&
                <React.Fragment>
                    {
                        (selectedIntegrationCalle ?? false) &&
                        <React.Fragment>
                            <div className={classes.nameRoot}>
                                <div className={classes.wrapName}>
                                    <Person color="primary" sx={styles.alignAvatar} />
                                    <Typography variant="body2">{integrationDropdownData.length > 0 ? integrationDropdownData[0].fieldName : "Name"}:</Typography>
                                </div>
                                <div className={classes.wrapDropdown}>
                                    <DropDown
                                        style={{ marginTop: 0, marginBottom: 0, width: '200px' }}
                                        className={classes.dropdownStype}
                                        size="small"
                                        value={selectedIntegrationCalle?.id ?? ""}
                                        onChange={(e) => handleIntegrationDataChange(e)}
                                        values={integrationDropdownData}
                                    />
                                    {
                                        showSyncIntegrationBtn &&
                                        <IconButton sx={styles.iconBtn} aria-label="sync" title={t("sync data")} size="small" onClick={syncIntegrationData}>
                                            <Link size="medium" />
                                        </IconButton>
                                    }
                                    <IconButton aria-label="sync" title={t("sync data")} size="small" onClick={refreshIntegrationDataHandle}>
                                        <Refresh size="medium" />
                                    </IconButton>
                                </div>
                            </div>
                            {
                                ((Object.keys(selectedIntegrationCalle?.url).length !== 0 && Object.keys(selectedIntegrationCalle?.url).some(key => selectedIntegrationCalle?.url[key] !== null)) && !itegrationItemLoading && (selectedIntegrationCalle?.id !== '00000000-0000-0000-0000-000000000000')) &&
                                <Typography variant="body2" sx={styles.typographyStyle}>
                                    <Contacts color="primary" sx={styles.alignAvatar} />
                                    Url:&nbsp;&nbsp; {selectedIntegrationCalle.url.value !== '' ? <span className={classes.infoFont}><a onClick={() => openIntegrationUrl(selectedIntegrationCalle.url.value)} href="#">{selectedIntegrationCalle.url.key}</a></span> : '-'}
                                </Typography>
                            }
                            {
                                ((selectedIntegrationCalle?.items ?? false) && !itegrationItemLoading) &&
                                selectedIntegrationCalle.items.map(item => (
                                    <Typography variant="body2" sx={styles.typographyStyle} key={item.key}>
                                        <Info color="primary" sx={styles.alignAvatar} />
                                        {item.key}:&nbsp;&nbsp; <span className={classes.infoFont}>{item.value}</span>
                                    </Typography>
                                ))
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
            }
            {
                (props.userInput ?? false) &&
                <Typography variant="body2" sx={styles.typographyStyle}>
                    <Input color="primary" sx={styles.alignAvatar} />
                    {t("User Input")}:&nbsp;&nbsp; <span className={classes.infoFont}>{props.userInput}</span>
                </Typography>
            }
        </React.Fragment>
    );
};

ContactInfo.propTypes = {
    source: PropTypes.string,
    queueId: PropTypes.string,
    email: PropTypes.string,
}

export default ContactInfo;