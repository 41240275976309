import React, { useEffect, useRef } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '../../../components/CKEditor5/CustomCKEditor';
import { useTheme } from '@mui/styles';
import { ConversationStatus, ConversationType, EmailOperation } from '../../../helpers/Constant';
import { ConversationService } from '../../../services/ConversationService';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageAction, updateContent, updateFiles, setParticipants, resetParticipants, resetForwardEmail } from '../ConversationSlice';
import { formatEmailContent, formatToLongDateTime, HandleServerResponse, hasSystemKeywords, replaceKeywords } from '../../../helpers/Common';
import MessageHeader from '../components/MessageHeader';
import MessageTemplate from '../components/dialogs/MessageTemplate';
import AutoReply from '../ai/AutoReply';
import AiDialog from '../ai/AiDialog';
import AutoReplyPlugin from '../components/CustomAutoRplyButton';
import AiMenuPlugin from '../components/CustomAIButton';
import { styled } from '@mui/material/styles';
import CustomTemplatePlugin from '../components/CustomTemplateButton';
import { UserService } from '../../../services/UserService';

const DarkTheme = React.lazy(() => import('../../settings/list/ckeditorTheme/DarkTheme'));

const forwardContentConfig = {
    toolbar: false,
    language: 'tr',
    baseFloatZIndex: '9999',
    isReadOnly: true,
};

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    border: '1px solid #0000001f',
    borderRadius: '4px'
}));

const EditorContainer = styled('div')(({ theme }) => ({
    width: '100%',
    '& .ck-editor__editable_inline': {
        minHeight: '200px',
        borderTop: '1px solid #0000001f',
        border: 'none'
    },
    '& .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content': {
        border: 'none',
        borderTop: '1px solid #0000001f !important',
        borderBottom: '1px solid #0000001f !important',
        borderRadius: '0'
    }
}));

const ForwardContentCKEditorRoot = styled('div')(({ theme }) => ({
    width: '100%',
    '& .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content': {
        border: 'none',
        borderTop: '1px solid lightgray'
    },
    '& .ck-read-only': {
        border: 'none',
        paddingLeft: '10px',
    },
}));

const convertTextToHtml = (text) => {
    if (!text) return '';

    // Split text by double line breaks (\n\n)
    const paragraphs = text.split(/\n\n/);

    return paragraphs
        .map(paragraph => {
            // Handle single line breaks within paragraphs
            const lines = paragraph.trim().split(/\n/);
            if (lines.length === 1) {
                return paragraph.trim();
            }
            return lines.map(line => line.trim()).join('<br>');
        })
        .filter(para => para.length > 0)  // Remove empty paragraphs
        .join('</p><p>&nbsp;</p><p>');  // This creates exactly one empty line between paragraphs
};

// Function to insert HTML content into CKEditor
const insertHtmlContent = (editor, htmlContent) => {
    const viewFragment = editor.data.processor.toView(htmlContent);
    const modelFragment = editor.data.toModel(viewFragment);
    editor.model.insertContent(modelFragment);
};

const Message = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [openAutoReply, setOpenAutoReply] = React.useState(false);
    const [openMessageTemplate, setOpenMessageTemplate] = React.useState(false);
    const [selectedText, setSelectedText] = React.useState("");
    const [openAiDialog, setOpenAiDialog] = React.useState(false);
    const [selectedAiOption, setSelectedAiOption] = React.useState(null);
    const [errors, setErrors] = React.useState({ to: '', cc: '', bcc: '' });

    const { id, status, userId, lastEmail, messageAction, content, files, participants, forwardEmailContent, forwardEmailId } = useSelector(state => state.conversation.current);
    const { templates } = useSelector((state) => state.conversation);
    const selectedConversation = useSelector(state => state.conversation.current)

    const theme = useTheme();
    const editorRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (lastEmail && messageAction === "reply") {
            dispatch(setParticipants({
                to: [lastEmail.toRecipient],
                cc: lastEmail.ccRecipients,
                bcc: lastEmail.bccRecipients
            }));
        }
    }, [lastEmail, messageAction, dispatch]);

    useEffect(() => {
        // Function to remove the powered by label
        const removePoweredByLabel = () => {
            // The label typically appears in a div with class "ck-powered-by"
            const poweredByElement = document.querySelector('.ck-powered-by');
            if (poweredByElement) {
                poweredByElement.remove();
            }
        };

        // Create a MutationObserver to watch for the label
        const observer = new MutationObserver((mutations) => {
            mutations.forEach(() => {
                removePoweredByLabel();
            });
        });

        // Start observing the document with the configured parameters
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });

        // Cleanup function
        return () => {
            observer.disconnect();
        };
    }, []);

    const handleEditorTxt = (event, editor) => {
        const data = editor.getData();
        dispatch(updateContent(data));
    }

    const handleMessageTemplate = () => {
        setOpenMessageTemplate(true)
    };

    const handleAutoReply = () => {
        setOpenAutoReply(true)
    };

    const handleAiOption = (option) => {
        setSelectedAiOption(option);
        handleSelection();
        setOpenAiDialog(true);
    }

    const handleSignatureOption = (option) => {
        if (hasSystemKeywords(option.content)) {
            (async () => {
                const result = await UserService.GraphUserDetails();
                if (result.success) {
                    const keywordValues = {
                        "CUSTOMER.NAME": selectedConversation.threads[selectedConversation.threads.length - 1].createdBy,
                        "SUBJECT": selectedConversation.threads[selectedConversation.threads.length - 1].subject,
                        "CUSTOMER.EMAIL": selectedConversation.threads[selectedConversation.threads.length - 1].from,
                        "DATE": formatToLongDateTime(selectedConversation.threads[selectedConversation.threads.length - 1].date),
                        "AGENT.NAME": result.data.displayName ?? "Not found",
                        "AGENT.JOBTITLE": result.data.jobTitle ?? "Not found",
                        "AGENT.EMAIL": result.data.mail ?? "Not found",
                        "AGENT.MOBILE": result.data.mobilePhone ?? "Not found",
                        "AGENT.OFFICELOCATION": result.data.officeLocation ?? "Not found",
                    }

                    const replaceMessage = replaceKeywords(option.content, keywordValues);
                    handleInsertHtmlInEditor(replaceMessage)
                }
            })();
        } else {
            handleInsertHtmlInEditor(option.content)
        }
    }

    const handleSelection = () => {
        if (editorRef.current) {
            const selection = editorRef.current.model.document.selection;
            const selectedRange = selection.getFirstRange();
            let selectedText = '';
            // Iterate over the selected range to get the text
            for (const item of selectedRange.getItems()) {
                if (item.is('textProxy')) {
                    selectedText += item.data;
                }
            }
            setSelectedText(selectedText); // Update selected text state
        }
    }

    const handleReplaceTextInEditor = (newText) => {
        if (!editorRef.current) return;

        const editor = editorRef.current;

        try {
            editor.model.change(writer => {
                // Get the selected range
                const selection = editor.model.document.selection;

                if (!newText.includes('\n')) {
                    // Simple text replacement for single-line content
                    editor.model.deleteContent(selection);
                    editor.model.insertContent(writer.createText(newText));
                    return;
                }

                // Convert text with line breaks to HTML
                let htmlContent = convertTextToHtml(newText);

                // Wrap in paragraph tags if needed
                const wrappedHtml = htmlContent.startsWith('<p>')
                    ? htmlContent
                    : `<p>${htmlContent}</p>`;

                // Convert to model fragment
                const viewFragment = editor.data.processor.toView(wrappedHtml);
                const modelFragment = editor.data.toModel(viewFragment);

                // First delete the selected content
                editor.model.deleteContent(selection);

                // Then insert the new content at the current selection position
                editor.model.insertContent(modelFragment);

                // Move selection to the end of inserted content
                writer.setSelection(
                    writer.createPositionAfter(
                        editor.model.document.selection.getLastPosition().parent
                    )
                );
            });
        } catch (error) {
            console.error('Error replacing content in CKEditor:', error);
        }
    };

    const handleInsertTextInEditor = (newText) => {
        if (!editorRef.current) return;

        const editor = editorRef.current;

        try {
            editor.model.change(writer => {
                // Get the current selection
                const selection = editor.model.document.selection;
                const position = selection.getLastPosition();

                // If there are no line breaks, use simple text insertion
                if (!newText.includes('\n')) {
                    writer.insertText(newText, position);
                    return;
                }

                // Convert text with line breaks to HTML
                let htmlContent = convertTextToHtml(newText);

                // Wrap the content in paragraph tags if not already wrapped
                const wrappedHtml = htmlContent.startsWith('<p>')
                    ? htmlContent
                    : `<p>${htmlContent}</p>`;

                // Insert the HTML content
                insertHtmlContent(editor, wrappedHtml);
            });
        } catch (error) {
            console.error('Error inserting content into CKEditor:', error);
        }
    };

    const handleInsertHtmlInEditor = (newHtmlContent) => {
        if (!editorRef.current) return;

        const editor = editorRef.current;

        try {
            editor.model.change(writer => {
                // Convert HTML string to a document fragment
                const viewFragment = editor.data.processor.toView(newHtmlContent);
                const modelFragment = editor.data.toModel(viewFragment);

                // Insert the model fragment at the current selection
                editor.model.insertContent(modelFragment, editor.model.document.selection);
            });
        } catch (error) {
            console.error('Error inserting HTML content into CKEditor:', error);
        }
    };

    const handleReply = async () => {
        if (participants.to.length === 0 && messageAction === "forward") {
            setErrors((prev) => ({
                ...prev,
                to: 'Please add atleast one email'
            }));
            return;
        }

        setIsLoading(true);
        let formData = new FormData();

        formData.append("Id", id);
        formData.append("ConversationType", ConversationType.Email);
        formData.append("ConversationOperation", messageAction === "reply" ? EmailOperation.Reply : EmailOperation.Forward);
        formData.append("EmailId", forwardEmailId ?? lastEmail?.id);
        formData.append("Body", formatEmailContent(content));
        participants.cc.forEach(email => formData.append('CcRecipientEmailList', email));
        participants.bcc.forEach(email => formData.append('BccRecipientEmailList', email));
        participants.to.forEach(email => formData.append('ToRecipientEmailList', email));
        files.forEach(file => {
            formData.append("Attachments", file)
        });

        let result = await ConversationService.ReplyEmail(formData);
        HandleServerResponse(result, "Email sent successfully", dispatch);

        if (result.success) {
            dispatch(updateContent(""));
            dispatch(updateFiles([]));
            dispatch(setMessageAction(null));
            dispatch(resetParticipants());
            dispatch(resetForwardEmail());
        }
        setIsLoading(false)
    }

    if (status === ConversationStatus.Completed || status === ConversationStatus.Declined || messageAction === null)
        return <></>

    return (
        <React.Fragment>
            <Root id='message-section'>
                <MessageHeader
                    handleReply={handleReply}
                    content={content}
                    isLoading={isLoading}
                    participants={participants}
                    errors={errors}
                    setErrors={setErrors}
                />
                <EditorContainer>
                    <React.Suspense fallback={<></>}>
                        {(theme.palette.mode === "dark") && <DarkTheme />}
                    </React.Suspense>
                    <CKEditor
                        editor={Editor}
                        data={content}
                        disabled={userId === null}
                        onChange={(event, editor) => handleEditorTxt(event, editor)}
                        onReady={(editor) => {
                            editorRef.current = editor;
                            const { plugins } = editor;

                            const aiPlugins = plugins.get(AiMenuPlugin);
                            aiPlugins.setCallback(handleAiOption);

                            const autoReplyPlugins = plugins.get(AutoReplyPlugin);
                            autoReplyPlugins.setCallback(handleAutoReply);

                            const templatePlugins = plugins.get(CustomTemplatePlugin);
                            templatePlugins.setCallback(handleSignatureOption);
                            templatePlugins.setReferenceData(templates);
                        }}
                    />
                </EditorContainer>
                {forwardEmailId &&
                    <ForwardContentCKEditorRoot>
                        <CKEditor editor={Editor} data={forwardEmailContent} disabled={true} config={forwardContentConfig} />
                    </ForwardContentCKEditorRoot>
                }
            </Root>
            {
                openAutoReply &&
                <AutoReply
                    setOpenAutoReply={setOpenAutoReply}
                    handleReplaceText={handleReplaceTextInEditor}
                    handleInsertText={handleInsertTextInEditor}
                    open={openAutoReply}
                />
            }
            {
                openMessageTemplate &&
                <MessageTemplate
                    setOpenMessageTemplate={setOpenMessageTemplate}
                    handleReplaceText={handleReplaceTextInEditor}
                    handleInsertText={handleInsertTextInEditor}
                    open={openMessageTemplate}
                />
            }
            {
                openAiDialog &&
                <AiDialog
                    setOpenAiDialog={setOpenAiDialog}
                    open={openAiDialog}
                    selectedAiOption={selectedAiOption}
                    selectedText={selectedText}
                    setSelectedAiOption={setSelectedAiOption}
                    handleReplaceText={handleReplaceTextInEditor}
                    handleInsertText={handleInsertTextInEditor}
                />
            }
        </React.Fragment>
    )
}

export default Message