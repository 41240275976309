import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import GroupBox from '../../../components/GroupBox';
import Form from '../../../components/form/Form';
import GroupContainer from '../../../components/GroupContainer';
import Input from '../../../components/Inputs/Input';
import UseForm from '../../../components/form/UseForm';
import { Button } from '@mui/material';
import AutoComplete from "../../../components/Inputs/AutoComplete";
import { Close, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Link } from "react-router-dom";
import AutoCompleteExtended from '../../../components/Inputs/AutoCompleteExtended';

const useStyles = makeStyles(theme => ({
    organisationInfo: {
        width: '100%',
        padding: 12
    },
    btnDiv: {
        marginTop: 16,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },

}));

var initialValues = {
    firstName: '',
    lastName: '',
    businessPhone: '',
    businessEmail: '',
    companyName: '',
    website: '',
    level: '',
    country: '',
    timezone: ''
}

export default function RegisterOrganizationInfo(props) {
    const classes = useStyles();
    const theme = useTheme();
    const styles = {
        previousBtn: {
            marginRight: theme.spacing(2),
            width: 100
        },
        nextBtn: {
            width: 100
        }
    }

    const [loading, setLoading] = React.useState(false);

    const emailValidation = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return "";
        }
        return "Enter valid email address";
    }

    const validate = (fieldValues = values) => {
        let validationProps = { ...errors };

        if ('firstName' in fieldValues)
            validationProps.firstName = fieldValues.firstName !== '' ? "" : "Required."

        if ('lastName' in fieldValues)
            validationProps.lastName = fieldValues.lastName !== '' ? "" : "Required."

        if ('businessPhone' in fieldValues)
            validationProps.businessPhone = fieldValues.businessPhone !== '' ? "" : "Required."

        if ('businessEmail' in fieldValues) {
            if (fieldValues.businessEmail === '') {
                validationProps.businessEmail = "Required.";
            } else {
                validationProps.businessEmail = emailValidation(fieldValues.businessEmail);
            }
        }

        if ('companyName' in fieldValues)
            validationProps.companyName = fieldValues.companyName !== '' ? "" : "Required."

        if ('level' in fieldValues)
            validationProps.level = fieldValues.level !== '' ? "" : "Required."

        if ('country' in fieldValues)
            validationProps.country = fieldValues.country !== '' ? "" : "Required."

        if ('timezone' in fieldValues)
            validationProps.timezone = fieldValues.timezone !== '' ? "" : "Required."

        setErrors({
            ...validationProps
        });

        if (fieldValues === values)
            return Object.values(validationProps).every(x => x === "");
    }

    const {
        values,
        setValues,
        handleInputChanges,
        errors,
        setErrors,
    } = UseForm(initialValues, true, validate);

    useEffect(() => {
        const initializeData = async () => {
            try {
                setLoading(true);

                if (props.orgInformation !== null) {
                    setValues(props.orgInformation);
                }

                // Note on Country Detection:
                // Browsers don't provide direct access to user's country for privacy reasons.
                // We can only get timezone and language preferences from the browser.
                // Therefore, we need to use IP geolocation services (ipapi.co) to detect the country.
                // This is a common approach but depends on:
                // 1. The IP geolocation service being available
                // 2. The user not using a VPN
                // 3. The accuracy of the IP-to-location database
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();

                // Note on Timezone Detection Strategy:
                // Browser's Intl.DateTimeFormat() returns IANA timezone IDs (e.g., "Asia/Colombo")
                // while our timezone list uses Microsoft Windows timezone IDs (e.g., "Sri Lanka Standard Time").
                // Instead of maintaining a large mapping between these two formats (which would need 100+ entries),
                // we use UTC offset matching as a more maintainable solution.
                // This approach:
                // 1. Gets the browser's UTC offset (e.g., +05:30)
                // 2. Formats it to match how it appears in our timezone names (e.g., "UTC+05:30")
                // 3. Finds a timezone in our list that matches this offset
                // This is more reliable than maintaining a manual IANA-to-Windows timezone mapping.
                const browserDate = new Date();
                const offsetInMinutes = -browserDate.getTimezoneOffset();
                const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
                const minutes = Math.abs(offsetInMinutes) % 60;
                const utcString = `UTC${offsetInMinutes >= 0 ? '+' : '-'}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                const detectedCountry = props.references.countries.find(
                    country => country.name.toLowerCase() === data.country_name.toLowerCase()
                );

                let newValues = { ...values };

                if (detectedCountry) {
                    newValues.country = detectedCountry;
                }

                // Try to find timezone by matching both UTC offset AND country name if possible
                let detectedTimezone = null;

                if (detectedCountry) {
                    detectedTimezone = props.references.timezones.find(tz =>
                        tz.name.toLowerCase().includes(detectedCountry.name.toLowerCase()) ||
                        tz.name.toLowerCase().includes(detectedCountry.id.toLowerCase())
                    );
                }

                // If no country-specific timezone found, fall back to UTC offset matching
                if (!detectedTimezone) {
                    detectedTimezone = props.references.timezones.find(tz => tz.name.includes(utcString));
                }

                newValues.timezone = detectedTimezone;

                setValues(newValues);
            } catch (error) {
                console.error('Error in initialization:', error);
                setValues({
                    ...values,
                    country: '',
                    timezone: ''
                });
            } finally {
                setLoading(false);
            }
        };

        initializeData();
    }, [props.orgInformation, props.references.timezones, props.references.countries]);

    const handleFormData = (e) => {
        e.preventDefault();

        if (validate()) {

            props.handleOrganisationInfo(values);
            props.handleSelectedComponent('review')
        }
    }

    return (
        <GroupBox title="Organisation Information" gutter className={classes.organisationInfo}>
            <Form>
                {!loading &&
                    <GroupContainer>
                        <Input
                            required
                            size="large"
                            name='companyName'
                            label="Organisation Name"
                            onChange={handleInputChanges}
                            value={values.companyName}
                            error={errors.companyName}
                        />
                        <Input
                            required
                            size="large"
                            name='firstName'
                            label="First Name"
                            onChange={handleInputChanges}
                            value={values.firstName}
                            error={errors.firstName}
                        />
                        <Input
                            required
                            size="large"
                            name='lastName'
                            label="Last Name"
                            onChange={handleInputChanges}
                            value={values.lastName}
                            error={errors.lastName}
                        />
                        <AutoComplete
                            required
                            full={true}
                            onChange={handleInputChanges}
                            options={props.references.levels}
                            label="Level"
                            name="level"
                            defaultValue={values.level}
                            error={errors.level}
                        />
                        <Input
                            required
                            size="large"
                            name='businessPhone'
                            label="Business Phone"
                            onChange={handleInputChanges}
                            value={values.businessPhone}
                            error={errors.businessPhone}
                        />
                        <Input
                            required
                            size="large"
                            name='businessEmail'
                            label="Business Email"
                            onChange={handleInputChanges}
                            value={values.businessEmail}
                            error={errors.businessEmail}
                        />
                        <Input
                            size="large"
                            name='website'
                            label="Website"
                            onChange={handleInputChanges}
                            value={values.website}
                        />
                        <AutoComplete
                            required
                            full={true}
                            onChange={handleInputChanges}
                            options={props.references.countries}
                            label="Country"
                            name="country"
                            defaultValue={values.country}
                            error={errors.country}
                        />
                        <AutoComplete
                            required
                            full={true}
                            onChange={handleInputChanges}
                            options={props.references.timezones}
                            label="Default Time Zone"
                            name="timezone"
                            defaultValue={values.timezone}
                            error={errors.timezone}
                        />
                        <div className={classes.btnDiv}>
                            <div>
                                <Button
                                    startIcon={<NavigateBefore />}
                                    sx={styles.previousBtn}
                                    onClick={() => props.handleSelectedComponent('consent', false)}
                                    variant="contained"
                                    color='grey'
                                    component="span">
                                    Previous
                                </Button>
                                <Button endIcon={<NavigateNext />} sx={styles.nextBtn} onClick={handleFormData} variant="contained" color="primary" component="span">
                                    Next
                                </Button>
                            </div>
                            <Button
                                style={{ marginRight: 16 }}
                                endIcon={<Close />}
                                variant="contained"
                                component={Link}
                                to="/"
                                color='grey'
                                sx={styles.nextBtn}
                            >
                                Close
                            </Button>
                        </div>
                    </GroupContainer>
                }
            </Form>
        </GroupBox>
    );
}