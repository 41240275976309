import React from 'react'

const MessageContent = ({ message, isOriginalVisible, toggleOriginalMessage }) => {
    const styles = {
        root: {
            display: 'block', 
            marginTop: '5px', 
            color: 'gray', 
            fontStyle: 'italic'
        },
        hide: {
            color: 'blue', 
            textDecoration: 'underline'
        },
        message: {
            display: 'block', 
            marginTop: '2px', 
            color: 'gray', 
            fontStyle: 'italic'
        }
    }

    return (
        <div>
            <div>{message?.translatedMessage}</div>
            <small style={styles.root}>
                Text translated{' '}
                <a
                    href="#"
                    onClick={(event) => {
                        event.preventDefault();
                        toggleOriginalMessage(message.id);
                    }}
                    style={styles.hide}
                >
                    {isOriginalVisible ? 'Hide original' : 'Show original'}
                </a>
            </small>
            {isOriginalVisible && (
                <small style={styles.message}>
                    {message?.originalMessage}
                </small>
            )}
        </div>
    );
};

export default MessageContent;