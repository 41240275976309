import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    flexbox: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    gutterBottom: {
        marginBottom: theme.spacing(1),
    },
    compactFlex: {
        display: 'flex',
        flexWrap: 'nowrap',
        minWidth: 0,
        width: 'fit-content'
    }
}));

export default function GroupContainer(props) {
    const { children, flex = true, gutter = false, compact = false, className, ...other } = props;
    const classes = useStyles();

    return (
        <div className={clsx(
            className,
            classes.root,
            { [classes.flexbox]: flex && !compact },
            { [classes.compactFlex]: compact },
            { [classes.gutterBottom]: gutter },
        )} {...other}>
            {children}
        </div>
    );
}